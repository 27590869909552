.input-icon {
  margin-right: 5px;
  &.field-error {
    align-self: center;
  }
}
.show-password-icon {
  &:hover {
    cursor: pointer;
  }
}
