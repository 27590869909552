.students-page-container {
  display: grid;
  justify-items: center;
  justify-content: center;
  padding-top: 20px;

  .table-container {
    margin-top: 60px;
  }

  .licensure-disclosure-wrp {
    display: grid;
    justify-items: flex-start;
  }
}

.settings-wrp {
  display: flex;
  position: absolute;
  align-content: center;
  padding: 2px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: rgba(215, 214, 214, 0.712);
  }

  .settings-container {
    position: absolute;
    padding: 5px;
    left: 30px;
    top: 0px;
    background-color: rgb(216, 218, 218);
    border: 1px solid black;
    border-radius: 5px;
    cursor: default;

    .settings-option {
      display: flex;
      align-items: center;
      margin: 5px;
      padding: 2px;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
        background-color: rgba(118, 117, 117, 0.479);
      }

      span {
        padding: 2px;
        margin-left: 5px;
      }
    }
  }
}
