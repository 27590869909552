.organization-list-container {
  display: grid;
  justify-items: center;
  justify-content: center;
  padding-top: 20px;

  .organization-options-wrp {
    display: flex;
    width: 90%;
    justify-content: right;
    margin-top: 25px;
    &.center {
      justify-content: center;
      margin-top: 50px;
    }
  }

  .table-container {
    margin-top: 20px;
    // max-width: 90%;
    overflow: hidden;
    .table-content {
      overflow-x: hidden;
    }
  }

  .org-view-button {
    :hover {
      cursor: pointer;
    }
  }
  .org-edit-button {
    :hover {
      cursor: pointer;
    }
  }
}

.create-admin-input-wrp {
  display: grid;
  justify-items: center;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  #organization-name-title {
    margin-top: 10px;
    label {
      margin-left: 2px;
      text-transform: capitalize;
    }
    color: gray;
  }
  .input-field {
    margin-top: 10px;
    width: 400px;

    @media (width < 800px) {
      width: 100%;
    }
    .input-country-code {
      max-width: 40%;
    }
    .MuiFormLabel-root {
      font-weight: bold;
    }
  }
  .error-message {
    display: grid;
    justify-content: center;
    margin-top: 20px;
  }
  .response-message {
    display: grid;
    justify-content: center;
    font-size: medium;
    margin-top: 20px;
  }
  .submit-button {
    margin-top: 21px;
    font-weight: bold;
    border: 2px solid rgba(0, 0, 0, 0.5);
    &:hover {
      border: 2px solid black;
    }
  }
  .close-button {
    margin-top: 21px;
    font-weight: bold;
    border: 2px solid rgba(0, 0, 0, 0.5);
    margin-left: 15px;
    &:hover {
      border: 2px solid black;
    }
  }
}
