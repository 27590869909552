.search-wrp {
  position: relative;
  .search-input {
    border-radius: 50px;

    font-size: medium;
    .MuiOutlinedInput-root {
      border-radius: 50px;
    }
  }

  .search-drop-down {
    position: absolute;
    width: calc(100% - 50px);
    margin: 0 25px;
    border: 0.5px solid;
    border-radius: 5px;
    z-index: 10;
    max-height: 50vh;
    overflow-y: auto;
    &.hide {
      display: none;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 2px;
      transition:
        opacity 0.3s,
        max-height 0.3s ease-in-out;

      li {
        padding: 2px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .suggestions-list {
      border-bottom: 2px solid rgba(0, 0, 0, 0.839);
    }
    span {
      display: flex;
      margin-left: 5px;
      margin-top: 5px;
      font-weight: bold;
    }
  }
}
