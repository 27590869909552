.programs-page-container {
  display: grid;
  justify-items: center;
  justify-content: center;
  padding-top: 20px;

  .table-container {
    margin-top: 60px;
    max-width: 90%;
    overflow-x: auto;
  }

  .licensure-disclosure-wrp {
    display: grid;
    justify-items: flex-start;
    margin-top: 60px;
  }
}

.eligibility-yes {
  color: green;
}

.eligibility-no {
  color: red;
}
