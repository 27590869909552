.navbar-container {
  position: sticky !important;
  // background-color: #ffff !important;
  // color: black !important;
  box-shadow: none !important;

  .logo {
    max-width: 150px;
  }

  .current-page {
    font-weight: bold;
  }
}
