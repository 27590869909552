.login-page-container {
  display: grid;
  justify-content: center;
  align-self: center;
  .login-input-wrp {
    display: grid;
    justify-items: center;
    padding: 50px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    .logo-wrp {
      display: grid;
      justify-content: center;
      margin: 20px;

      .logo {
        max-width: 200px;
      }
    }

    .input-field {
      margin-top: 20px;
      width: 400px;

      @media (width < 800px) {
        width: 100%;
      }

      .MuiFormLabel-root {
        font-weight: bold;
      }
    }
    .forgot-password-link {
      display: grid;
      justify-content: right;
      margin: 5px;
      text-decoration: underline;
      font-size: small;
      &:hover {
        cursor: pointer;
      }
    }
    .error-message {
      display: grid;
      justify-content: center;
      // font-size: medium;
      // color: #e72f2f;
    }
    .login-button {
      margin-top: 21px;
      // color: #000000;
      font-weight: bold;
      border: 2px solid rgba(0, 0, 0, 0.5);
      &:hover {
        border: 2px solid black;
      }
    }
  }
}
