.layout-container {
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  .layout-wrp {
    height: 100%;
    overflow-x: hidden;
  }
  .body-container {
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: calc(100% - 20px);
    padding: 10px;
    min-height: 80vh;
    overflow-y: auto;
  }
}
.theme-switcher-wrp {
  position: absolute;
  right: 0;
}
.MuiPaper-rounded {
  border-radius: 0 !important;
}
