.drawer-list-text {
  text-align: right;
}
.drawer-top-icons {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  .drawer-extra-icons {
    display: inline-flex;
    justify-content: right;
    width: 100%;
    margin-right: 20px;

    span {
      margin: 5px;
    }
  }
}
.drawer-list-bottom {
  width: 100%;
  display: grid;
  position: absolute;
  bottom: 5%;

  .drawer-list-bottom-button {
    height: 25px;
  }
}
