.table-wrp {
  border-radius: 5px;
  border: 2px solid rgb(71, 70, 70);
  margin: 5px;
  padding: 1px;
  .MuiDataGrid-main {
    position: unset;

    .MuiDataGrid-virtualScroller {
      position: unset;
      overflow: hidden;
    }
  }

  .table-heading {
    padding: 0px 20px 0px 20px;
  }

  .download-icon {
    position: absolute;
    top: 5px;
    right: 20px;
  }

  .table-content {
    overflow: auto;

    .MuiDataGrid-root {
      overflow: auto;
      width: max-content;

      .MuiDataGrid-footerContainer {
        border: none;
        min-height: 20px;
      }

      .MuiDataGrid-cell {
        // border-color: rgb(71, 71, 71) !important;

        &:focus {
          outline: none;
        }
        &:hover {
          cursor: default;
        }
      }
      .MuiDataGrid-columnHeaders {
        // border-color: rgb(20, 19, 19);
        // background-color: rgb(146, 139, 139);
        // color: #ffff;
      }
      .MuiDataGrid-row .Mui-selected {
        border-color: none !important;
      }
    }
  }
}

.column-actionText {
  text-decoration: underline;
  cursor: pointer;
}
