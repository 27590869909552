.organization-input-wrp {
  display: grid;
  justify-items: center;
  padding: 50px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .input-field {
    margin-top: 20px;
    width: 400px;

    @media (width < 800px) {
      width: 100%;
    }

    .MuiFormLabel-root {
      font-weight: bold;
    }
  }
  .error-message {
    display: grid;
    justify-content: center;
    margin-top: 20px;
  }
  .response-message {
    display: grid;
    justify-content: center;
    font-size: medium;
    margin-top: 20px;
  }
  .submit-button {
    margin-top: 21px;
    font-weight: bold;
    border: 2px solid rgba(0, 0, 0, 0.5);
    &:hover {
      border: 2px solid black;
    }
  }
  .close-button {
    margin-top: 21px;
    font-weight: bold;
    border: 2px solid rgba(0, 0, 0, 0.5);
    margin-left: 15px;
    &:hover {
      border: 2px solid black;
    }
  }
}
